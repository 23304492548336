import "../../../css/Main.css";
import clickupLogo from "../../../images/clickup.png";
import { Select } from "antd";
import { useContext } from "react";
import ClickUpContext from "../../store/ClickUpStore";
import ClickUpService from "../../service/clickUpService";
import { observer } from "mobx-react-lite";
import AuthStore from "../../store/AuthStore";
import { Button, Box, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom";

function ClickUpListSelection() {
	const navigate = useNavigate()
	let authStore = useContext(AuthStore);
	let store = useContext(ClickUpContext);
	let service = new ClickUpService(authStore);

	let teamsOptions = store.teams.map(team => ({ value: team.id, label: team.name }));
	let spaceOptions = store.spaces.map(value => ({ value: value.id, label: value.name }));
	// let foldersOptions = store.folders.map(value => ({ value: value.id, label: value.name }));
	let foldersOptions = [
		{
		  value: 0,
		  label: '-',
		},
		...store.folders.map(value => ({
		  value: value.id,
		  label: value.name,
		}))
	  ];
	let listOptions = store.lists.map(value => ({ value: value.id, label: value.name }));

	if (!store.isRequestInProgress) {
		store.setIsRequestInProgress(true);
		if (store.teams.length === 0) {
			service.getWorkspaces().then(data => {
				let workspaces = data.teams;
				store.setTeams(workspaces);
				store.setIsRequestInProgress(false);
			}).catch((error) => {
				console.log(error)
				if (error.response.status === 401) {
					navigate("/")
				}
			});
		}
	}

	const onTeamsChange = (value) => {
		service.getSpaces(value).then(data => {
			let entityList = data.spaces;
			store.setSpaces(entityList);
		}).catch((error) => {
			console.log(error)
			if (error.response.status === 401) {
				navigate("/")
			}
		});
		store.setSpaces([])
		store.setFolders([]);
		store.setLists([])
	}

	const onSpaceChange = (value) => {
		store.setFolders([]);
		store.setLists([]);
		store.setSelectedSpaceId(value);
		service.getFolders(value).then(data => {
			let entityList = data.folders;
			store.setFolders(entityList);
		}).catch((error) => {
			console.log(error)
			if (error.response.status === 401) {
				navigate("/")
			}
		});
	}

	const onFolderChange = (value) => {
		if(value === 0) {
			if(store.selectedSpaceId !== 0) {
				service.getFolderlessLists(store.selectedSpaceId).then(data => {
					let entityList = data.lists;
					store.setLists(entityList);
				}).catch((error) => {
					console.log(error)
					if (error.response.status === 401) {
						navigate("/")
					}
				});
			}
		}
		else {
			service.getListsFromFolder(value).then(data => {
				let entityList = data.lists;
				store.setLists(entityList);
			}).catch((error) => {
				console.log(error)
				if (error.response.status === 401) {
					navigate("/")
				}
			});
		}
	}

	const onListChange = (value) => {
		store.setSelectedListId(value);
	}

	const onConfirmListButtonClick = async () => {
		if (store.selectedListId != null) {
			store.setIsRequestInProgress(false);
			store.setSelectedListConfirmed(true);
			navigate('/stream-table')
		}
	}

	return (
		<Box className="zoom-container">
			<div className="zoom-box" >
				<Box sx={{
					display: "flex",
					width: "276px",
					padding: "16px",
					justifyContent: "space-between",
					alignItems: "flex-start",
					alignSelf: "stretch",
					borderRadius: "14px",
					border: "1px solid rgba(24, 28, 48, 0.04)",
					background: "white",
					boxShadow: "0px 2px 8px 0px rgba(24, 28, 48, 0.10)",
					marginBottom: "8px"
				}}>
					<img src={clickupLogo} alt="Choose ClickUp" />
					<Typography sx={{
						color: "#181C30",
						margin: "auto",
						marginLeft: "8px",
						fontSize: "14px",
						fontStyle: "normal",
						fontWeight: 600,
						lineHeight: "16px"
					}}>ClickUp</Typography>
				</Box>
				<div style={{ marginTop: "40px" }}>
					<div className="title">Select Task List</div>
				</div>
				<div style={{ marginTop: "36px" }}>
					<Select
						placeholder={"Select Workspace"}
						size={"large"}
						style={{ width: "100%", borderRadius: "14px" }}
						onChange={onTeamsChange} options={teamsOptions}
					/>
				</div>
				<div style={{ marginTop: "16px" }}>
					<Select placeholder={"Select Space"} size={"large"} style={{ width: "100%" }} onChange={onSpaceChange} options={spaceOptions} />
				</div>
				<div style={{ marginTop: "16px" }}>
					<Select placeholder={"Select Folder"} size={"large"} style={{ width: "100%" }} onChange={onFolderChange} options={foldersOptions} />
				</div>
				<div style={{ marginTop: "16px", marginBottom: "36px" }}>
					<Select placeholder={"Select List"} size={"large"} style={{ width: "100%" }} onChange={onListChange} options={listOptions} />
				</div>
				<Box sx={{ width: '100%' }} alignItems='center' display='flex' flexDirection='column' gap={1}>
					<Button variant="contained" color="primary" onClick={onConfirmListButtonClick} fullWidth sx={{
						background: 'rgba(185, 121, 249, 1)',
						boxShadow: '0px 4px 16px 0px rgba(185, 121, 249, 0.32)',
						borderRadius: '20px',
						color: 'white',
						textTransform: 'none',
						height: '48px',
						fontSize: '14px',
					}}>
						Confirm
					</Button>
				</Box>
			</div>
		</Box>
	)
}

export default observer(ClickUpListSelection);