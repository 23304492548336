import React, { useState, useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Navigate, Routes } from "react-router-dom";
import './App.css';
import IntegrationChooser from "./js/integration/IntegrationChooser";
import { createTheme, ThemeProvider } from '@mui/material';
import Login from './js/component/Login';
import ClickUpListSelection from './js/integration/clickup/ClickUpListSelection';
import ClickUpContainer from './js/integration/clickup/ClickUpContainer';
import AirtableListSelection from './js/integration/airtable/AirtableListSelection';
import AirtableContainer from './js/integration/airtable/AirtableContainer';
import TasksList from './js/integration/TasksList';
import AuthContext from './js/store/AuthStore'
import AuthService from "./js/service/authService";
import {OnRunningContextChangeEvent} from "@zoom/appssdk";
import ZoomContext from "./js/store/ZoomStore";

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgba(185, 121, 249, 1)', // Light Purple color
      light: 'rgba(185, 121, 249, 0.83)'
    },
    secondary: {
      main: '#e6d6e9', // Another color in your gamma
    },
  },
  typography: {
    fontFamily: 'Mona Sans',
    body1: {
      fontSize: 12
    },
    body2: {
      fontSize: 14
    }
  }
});

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const authStore = useContext(AuthContext);

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      let authService = new AuthService(authStore);
      authService.getProtectedStatus().then(response => {
        if (response === 401) {
          localStorage.clear()
        }
        else if (response === 403) {
          localStorage.clear()
        }
      }).catch(e => {
        if (e.response.status === 401) {
          localStorage.clear();
        }
        else if (e.response.status === 403) {
          localStorage.clear();
        }
        else if (e.response.status !== 401) {
          console.log(e);
        }
      });
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log(isLoggedIn);
  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
  };

  const PrivateRoute = ({ children }) => {
    if (authStore.token) {
      return children
    }
    return <Navigate to="/login" />
  }

  const BaseRoute = ({ children }) => {
    if (!authStore.token) {
      return children
    }
    return <Navigate to="/tracking" />
  }

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/login" element={<BaseRoute><Login onLoginSuccess={handleLoginSuccess} /></BaseRoute>} />
          <Route path="/tracking" element={<PrivateRoute><IntegrationChooser /></PrivateRoute>} />
          <Route path="/list" element={<PrivateRoute><ClickUpListSelection /></PrivateRoute>} />
          <Route path="/airtable-list" element={<PrivateRoute><AirtableListSelection /></PrivateRoute>} />
          <Route path="/stream-table" element={<PrivateRoute><ClickUpContainer /></PrivateRoute>} />
          <Route path="/stream-airtable-table" element={<PrivateRoute><AirtableContainer /></PrivateRoute>} />
          <Route path="/tasks" element={<PrivateRoute><TasksList /></PrivateRoute>} />
          <Route path="/" element={<BaseRoute><Login onLoginSuccess={handleLoginSuccess} /></BaseRoute>} />
        </Routes>
      </Router>
    </ThemeProvider>
  )
}

export default App;
