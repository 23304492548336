import React from 'react'
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Task from './Task';

const UpdateStream = ({ data }) => {
  const [streamData, setStreamData] = useState()
  useEffect(() => {
    if (data) {
      setStreamData(data.map((item) => {
        let obj = {}
        let array = item.split("\n")
        array.forEach((value) => {
          if (value) {
            let pair = value.split(": ")
            obj[pair[0]] = pair[1]
          }
        })
        return obj
      }))
    }
  }, [data])

  return (
    <Box p={1} sx={{
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid rgba(24, 28, 48, 0.16)',
      borderRadius: 3.5,
      gap: 1,
      height: 488,
      overflowY: 'auto'
    }}>
      {streamData?.map((task, index) => <Task key={`task-${index}`} data={task} />)}
    </Box>
  )
}

export default UpdateStream;