import React, { useContext, useEffect, useState } from "react";
import clickupLogo from "../../images/clickup.png";
import ClickUpStore from "../store/ClickUpStore";
import { Box, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import ClickUpTasks from "./clickup/content/task/ClickUpTasks";
import ZoomRecording from "../recording/ZoomRecording";

const TasksList = () => {
  const navigate = useNavigate()
  let store = useContext(ClickUpStore);
  let selectedList = store.getListById(store.selectedListId);
  const [isActive, setIsActive] = useState(false)
  console.log(isActive);

  const handleMouseUp = () => {
    setIsActive(false)
  }

  useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [])

  return (
    <Box display='flex' justifyContent='center'>
      <Box px={2} py={3} width={356}>
        <Box display='flex' justifyContent='space-between' alignItems='center' mb={4.5}>
          <Typography sx={{
            color: "#181C30",
            fontSize: "12px",
            cursor: 'pointer',
          }} onClick={() => navigate('/stream-table')}>
            ← Back
          </Typography>
        </Box>
        <Box display='flex' flexDirection='column' gap={3}>
          <Box display='flex' alignItems='center'>
            <img src={clickupLogo} alt="" />
            <Typography variant='body2' sx={{
              color: "#181C30",
              ml: 0.5,
              fontWeight: 600
            }}>{selectedList?.name}</Typography>
          </Box>
          <Box p={1} sx={{
            display: 'flex',
            flexDirection: 'column',
            border: '1px solid rgba(24, 28, 48, 0.16)',
            borderRadius: 3.5,
            gap: 1,
            height: 488,
            overflowY: 'auto'
          }} className="scrollbar-radius">
            <ClickUpTasks />
          </Box>
          <ZoomRecording />
        </Box>
      </Box>
    </Box>
  )
}

export default TasksList;