import React from 'react';
import '../../../../../css/Main.css';

const Circle = ({ color, size, children }) => (
    <div
        style={{
            width: size,
            height: size,
            backgroundColor: color,
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}
    >
        {children}
    </div>
);

const RoundedRectangle = ({ color, size, borderRadius }) => (
    <div
        style={{
            width: size,
            height: size,
            backgroundColor: color,
            borderRadius: borderRadius,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}
    ></div>
);

export default function TaskPanelHeader({ task }) {
    const { status, name, assignees } = task;

    return (
        <div className="flex-container space-between">
            <div className="padding-left-1vh margin-right-1vh">
                <RoundedRectangle color={status.color} size="12px" borderRadius="3px" />
            </div>
            <div style={{ width: '100%', fontSize: '13px', paddingRight: 4 }}>{name}</div>
            <div>
                {assignees.length > 0 && (
                    <Circle color={assignees[0].color} size="26px">
                        <div style={{ color: 'white', textAlign: 'center', width: '100%', fontSize: '12px' }}>
                            {assignees[0].initials}
                        </div>
                    </Circle>
                )}
            </div>
        </div>
    );
}