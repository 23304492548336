import "../../../css/Main.css";
import airtableLogo from "../../../images/airtable.png";
import { Select } from "antd";
import { useContext } from "react";
import AirtableContext from "../../store/AirtableStore";
import AirtableService from "../../service/airtableService";
import { observer } from "mobx-react-lite";
import AuthStore from "../../store/AuthStore";
import UserCallService from "../../service/userCallService";
import ZoomContext from "../../store/ZoomStore";
import { Button, Box, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom";

function AirtableListSelection() {
	const navigate = useNavigate()
	let authStore = useContext(AuthStore);
	let zoomStore = useContext(ZoomContext);
	let store = useContext(AirtableContext);
	let service = new AirtableService(authStore);
	let userService = new UserCallService(authStore);

	let basesOptions = store.bases.map(team => ({ value: team.id, label: team.name }));
	let tablesOptions = store.tables.map(value => ({ value: value.id, label: value.name }));

	if (!store.isRequestInProgress) {
		store.setIsRequestInProgress(true);
		if (store.bases.length === 0) {
			service.getBases().then(data => {
				let bases = data.bases;
				store.setBases(bases);
				store.setIsRequestInProgress(false);
			}).catch((error) => {
				console.log(error)
				if (error.status && error.response.status === 401) {
					navigate("/")
				}
			});
		}
	}

	const onBaseChange = (value) => {
		store.setSelectedBaseId(value);
		service.getTables(value).then(data => {
			let entityList = data;
			store.setTables(entityList);
		}).catch((error) => {
			console.log(error)
			if (error.response.status === 401) {
				navigate("/")
			}
		});
		store.setTables([])
	}

	const onTableChange = (value) => { store.setSelectedTableId(value); }

	const onConfirmListButtonClick = async () => {
		if (store.selectedTableId != null) {
			store.setIsRequestInProgress(false);
			store.setSelectedListConfirmed(true);
			try {
				const result = await userService.selectAirtableBaseAndTable(store.selectedTableId, store.selectedBaseId, zoomStore.meetingUUID);
				if (!result) {
					setTimeout(onConfirmListButtonClick, 10000);
				} else {
					navigate('/stream-airtable-table')
				}
			} catch (error) {
				console.error("An error occurred:", error);
				setTimeout(onConfirmListButtonClick, 10000);
			}
		}
	}

	return (
		<Box className="zoom-container">
			<div className="zoom-box" >
				<Box sx={{
					display: "flex",
					width: "276px",
					padding: "16px",
					justifyContent: "space-between",
					alignItems: "flex-start",
					alignSelf: "stretch",
					borderRadius: "14px",
					border: "1px solid rgba(24, 28, 48, 0.04)",
					background: "white",
					boxShadow: "0px 2px 8px 0px rgba(24, 28, 48, 0.10)",
					marginBottom: "8px"
				}}>
					<img src={airtableLogo} alt="Choose Airtable" />
					<Typography sx={{
						color: "#181C30",
						margin: "auto",
						marginLeft: "8px",
						fontSize: "14px",
						fontStyle: "normal",
						fontWeight: 600,
						lineHeight: "16px"
					}}>Airtable</Typography>
				</Box>
				<div style={{ marginTop: "40px" }}>
					<div className="title">Select Task List</div>
				</div>
				<div style={{ marginTop: "36px" }}>
					<Select
						placeholder={"Select Base"}
						size={"large"}
						style={{ width: "100%", borderRadius: "14px" }}
						onChange={onBaseChange} options={basesOptions}
					/>
				</div>
				<div style={{ marginTop: "16px" }}>
					<Select placeholder={"Select Table"} size={"large"} style={{ width: "100%" }} onChange={onTableChange} options={tablesOptions} />
				</div>
				<Box sx={{ width: '100%', marginTop: 2 }} alignItems='center' display='flex' flexDirection='column' gap={1}>
					<Button variant="contained" color="primary" onClick={onConfirmListButtonClick} fullWidth sx={{
						background: 'rgba(185, 121, 249, 1)',
						boxShadow: '0px 4px 16px 0px rgba(185, 121, 249, 0.32)',
						borderRadius: '20px',
						color: 'white',
						textTransform: 'none',
						height: '48px',
						fontSize: '14px',
					}}>
						Confirm
					</Button>
				</Box>
			</div>
		</Box>
	)
}

export default observer(AirtableListSelection);