import axios from "axios";
import EndPointProvider from "../util/EndPointProvider";

export default class AirtableService {
  endpoint = "";
  settingEndpoint = "";
  token = "";
  userId = "";
  config;

  constructor(authStore) {
    this.endpoint = EndPointProvider.getEndPoint() + "/airtable/";
    this.settingEndpoint = EndPointProvider.getEndPoint() + "/settings/airtable/";
    this.token = authStore.token;
    this.userId = authStore.userId;
    this.config = {
      headers: { Authorization: `Bearer ${this.token}` },
    };
  }

  getBases = async () => {
    const response = await axios.get(this.endpoint + "bases",
        this.config);

    return response.data;
  };

  getTables = async (baseId) => {
    const response = await axios.get(
      this.endpoint + "tables?base_id=" + baseId,
        this.config
    );

    return response.data;
  };

  getSettingIntegration = async () => {
    const response = await axios.get(
      this.settingEndpoint + "/settingIntegration",
      this.config
    );

    return response.data;
  }

  updateSettingIntegration = async (data) => {
    const response = await axios.post(
      this.settingEndpoint + "/settingIntegration", data,
      this.config
    );
    
    return response.data;
  }
}
