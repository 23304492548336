import React from 'react'
import { observer } from "mobx-react-lite";
import airtableLogo from "../../../images/airtable.png";
import { useContext, useEffect, useState } from "react";
import AirtableStore from "../../store/AirtableStore";
import { Box, Tabs, Tab, Typography } from "@mui/material";
import RecordService from "../../service/recordService";
import AuthStore from "../../store/AuthStore";
import ZoomContext from "../../store/ZoomStore";
import UpdateStream from './content/UpdateStream';
import RoundTable from './content/RoundTable';
import { useNavigate } from 'react-router-dom';
import ZoomRecording from '../../recording/ZoomRecording';

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{
        mt: 1
      }}
      {...other}
    >
      {value === index && children}
    </Box>
  );
}

const AirtableContainer = () => {
  const navigate = useNavigate()
  let authStore = useContext(AuthStore);
  let store = useContext(AirtableStore);
  let recordService = new RecordService(authStore)
  let zoomStore = useContext(ZoomContext);
  let selectedList = store.getListById(store.selectedListId);
  const [value, setValue] = React.useState(store.selectedTab);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    store.setSelectedTab(newValue)
  };

  const [roundTableData, setRoundTableData] = useState([])
  const [streamData, setStreamData] = useState("")
  const [timerId, setTimerId] = useState()
  useEffect(() => {
    if (store.selectedListId === null && zoomStore.meetingUUID === "") {
      navigate("/list")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, store.selectedListId, zoomStore.meetingUUID])

  useEffect(() => {
    let id = setInterval(requestHandler, 10000)
    setTimerId(id)
    return () => {
      clearInterval(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const requestHandler = () => {
    if (store.selectedTab) {
      recordService.getRoundTableData("Airtable", null, zoomStore.meetingUUID, store.selectedBaseId, store.selectedTableId)
        .then(data => {
          let obj = {}
          let array = data.split("\n")
          array.forEach((value) => {
            if (value) {
              let pair = value.split(": ")
              obj[pair[0]] = pair[1]
            }
          })
          store.setIsRequestInProgress(false)
          setRoundTableData(obj)
        })
        .catch((error) => {
          store.setIsRequestInProgress(false)
          if (error.response.status === 401) {
            navigate("/")
          }
        })
    } else {
      recordService.getUpdateStream("Airtable", null, zoomStore.meetingUUID, store.selectedBaseId, store.selectedTableId)
        .then(data => {
          setStreamData(data)
          store.setIsRequestInProgress(false)
        })
        .catch((error) => {
          store.setIsRequestInProgress(false)
          if (error.response.status === 401) {
            navigate("/")
          }
        })
    }
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const inActiveCSS = {
    fontFamily: 'Mona Sans',
    fontWeight: 600,
    textTransform: 'none',
    px: 3,
    py: 1.5,
    maxHeight: 40,
    minHeight: 40,
    color: `rgba(24, 28, 48, 0.48)`,
    fontSize: 14
  }

  const activeCSS = {
    fontFamily: 'Mona Sans',
    fontWeight: 600,
    textTransform: 'none',
    px: 3,
    py: 1.5,
    maxHeight: 40,
    minHeight: 40,
    color: `rgba(185, 121, 249, 1) !important`,
    fontSize: 14,
    bgcolor: 'white',
    boxShadow: `0px 2px 8px 0px rgba(24, 28, 48, 0.1)`,
    borderRadius: '14px'
  }

  return (
    <Box display='flex' justifyContent='center'>
      <Box px={2} py={3} width={356} gap={3} display='flex' flexDirection='column'>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Box display='flex' alignItems='center'>
            <img src={airtableLogo} alt="" />
            <Typography sx={{
              color: "#181C30",
              ml: 0.5,
              fontSize: "12px",
              fontWeight: 600
            }}>{selectedList?.name}</Typography>
          </Box>
          <Typography sx={{
            color: "#181C30",
            fontSize: "12px",
            cursor: 'pointer'
          }} onClick={() => {
            store.setSelectedTab(value)
            clearInterval(timerId)
            navigate('/tasks')
          }}>
            Tasks in this list →
          </Typography>
        </Box>
        <Box>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{
            bgcolor: 'rgba(24, 28, 48, 0.04)',
            borderRadius: '18px',
            width: 324,
            p: 0.5,
          }}>
            <Tab label="Update Stream" {...a11yProps(0)} sx={{...(value === 0 ? activeCSS : inActiveCSS), width: 152}} />
            <Tab label="Task Roundtable" {...a11yProps(1)} sx={{...(value === 1 ? activeCSS : inActiveCSS), width: 164}} />
          </Tabs>
          <CustomTabPanel value={value} index={0}>
            <UpdateStream data={streamData} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <RoundTable data={roundTableData} />
          </CustomTabPanel>
        </Box>
        <ZoomRecording />
      </Box>
    </Box>
  )
}

export default observer(AirtableContainer);