import arrowIcon from "../../images/arrow.png";
import { Box, Typography } from "@mui/material";

type CustomButtonProps = {
  clickHandle: Function,
  title: string,
  imgUrl: string,
  status: boolean
}

const CustomButton: React.FC<CustomButtonProps> = ({clickHandle, title, imgUrl, status}) => {

    return (
      <Box sx={{
        display: "flex",
        width: "276px",
        padding: "16px",
        justifyContent: "space-between",
        alignItems: "flex-start",
        alignSelf: "stretch",
        borderRadius: "14px",
        border: "1px solid rgba(24, 28, 48, 0.04)",
        background: "white",
        boxShadow: "0px 2px 8px 0px rgba(24, 28, 48, 0.10)",
        cursor: "pointer",
        marginBottom: "8px"
      }} onClick={() => clickHandle()}>
        <img src={imgUrl} alt="Choose ClickUp"/>
        <Typography sx={{
              color: "#181C30",
              margin: "auto",
              marginLeft: "8px",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "16px"
        }}>{title}</Typography>
        {!status?
        <Box sx={{
          display: "flex",
          height: "24px",
          padding: "6px 12px",
          justifyContent: "center",
          alignItems: "center",
          gap: "8px",
          borderRadius: "10px",
          background: "rgba(24, 28, 48, 0.04)",
        }}>
          <Typography sx={{
            fontSize: "12px",
            fontWeight: 600,
            lineHeight: "16px",
          }}>coming soon</Typography>
        </Box>
        :<img src={arrowIcon} alt="Arrow"/>}
        
      </Box>
    )
}

export default CustomButton;
