import {makeAutoObservable} from 'mobx';
import {createContext} from "react";

class AuthStore {
    token: string | null = localStorage.getItem('authToken');
    userId: string | null = localStorage.getItem('userId');

    constructor() {
        makeAutoObservable(this);
    }

    setToken(token: string) {
        this.token = token;
    }

    getBearerHeader() {
        return {
            headers: {Authorization: `Bearer ${this.token}`}
        }
    }

    removeToken() {
        this.token = null;
    }

    setUserId(userId: string) {
      this.userId = userId;
    }
  
    removeUserId() {
      this.userId = null;
    }
}

const AuthContext = createContext(new AuthStore());
AuthContext.displayName = "AuthContext";
export default AuthContext;