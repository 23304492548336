import EndPointProvider from "../util/EndPointProvider";
import axios from "axios";

export default class RecordService {
  endpoint = '';
  token = '';
  config;

  constructor(authStore) {
    this.endpoint = EndPointProvider.getEndPoint() + "/zoom-call";
    this.token = authStore.token;
    this.config = {
      headers: { Authorization: `Bearer ${this.token}` }
    }
  }
  
  getCommandStatus = async (integration, selectedListId, meetingUUID, baseId, tableId) => {
    const response = await axios.get(this.endpoint + `/${meetingUUID === "" ? 'mWYvpjXxS7aOX20NXruZWA==' : meetingUUID}/processing-requested` , this.config);
    // console.log(response);
    return response.data;
  }

  getRoundTableData = async (integration, selectedListId, meetingUUID, baseId, tableId) => {
    const response = await axios.post(this.endpoint + '/roundtable', {
      integration,
      selectedListId,
      meetingUUID : meetingUUID === "" ? 'mWYvpjXxS7aOX20NXruZWA==' : meetingUUID,
      baseId,
      tableId
    }, this.config);
    return response.data;
  }

  getUpdateStream = async (integration, selectedListId, meetingUUID, baseId, tableId) => {
    const response = await axios.post(this.endpoint + '/update-stream', {
      integration,
      selectedListId,
      meetingUUID : meetingUUID === "" ? 'mWYvpjXxS7aOX20NXruZWA==' : meetingUUID,
      baseId,
      tableId
    }, this.config);
    return response.data;
  }

  applyChanges = async (changedTask) => {
    const response = await axios.post(EndPointProvider.getEndPoint() + '/changes/tasks/process', changedTask, this.config);

    return response.data;
  }
}