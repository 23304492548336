import { makeAutoObservable } from 'mobx';
import {createContext} from "react";

class IntegrationStore {
    integration: string | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    setIntegration(token: string) {
        this.integration = token;
    }

    removeIntegration() {
        this.integration = null;
    }
}

const IntegrationContext = createContext(new IntegrationStore());
IntegrationContext.displayName = "IntegrationContext";
export default IntegrationContext;