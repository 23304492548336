import {makeAutoObservable} from "mobx";
import {createContext} from "react";

class ClickUpStore {
    teams = [];
    spaces = [];
    folders = [];
    lists = [];
    tasks =[];

    selectedListId = null;
    selectedSpaceId = null;
    isSelectedListConfirmed = false;
    isRequestInProgress = false;
    selectedTab = 0

    constructor() {
        makeAutoObservable(this);
    }
    setSelectedTab(value) {
        this.selectedTab = value
    }

    setTeams(teams){
        this.teams = teams;
    }

    setSpaces(value) {
        this.spaces = value;
    }

    setFolders(value) {
        this.folders = value;
    }

    setLists(value) {
        this.lists = value;
    }

    setTasks(value) {
        this.tasks = value;
    }

    setIsRequestInProgress(isInProgress){
        this.isRequestInProgress = isInProgress;
    }

    setSelectedListId(listId){
        this.selectedListId = listId;
    }

    setSelectedSpaceId(spaceId) {
        this.selectedSpaceId = spaceId;
    }

    setSelectedListConfirmed(confirmed){
        this.isSelectedListConfirmed = confirmed;
    }

    getListById(listId){
        return this.lists.find(x => x.id === listId);
    }
}

const ClickUpContext = createContext(new ClickUpStore());
ClickUpContext.displayName = "ClickUpContext";

export default ClickUpContext;