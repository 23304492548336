import { observer } from "mobx-react-lite";
import { Collapse } from "antd";
import TaskPanelHeader from "./TaskPanelHeader";
import { useContext, useState, useEffect, useCallback } from "react";
import ClickUpStore from "../../../../store/ClickUpStore";
import ClickUpService from "../../../../service/clickUpService";
import AuthContext from "../../../../store/AuthStore";
import { Box, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

function ClickUpTasks() {
    const navigate = useNavigate()
    const authStore = useContext(AuthContext);
    const service = new ClickUpService(authStore);
    const store = useContext(ClickUpStore);
    const [panels, setPanels] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const fetchTasks = useCallback(() => {
        if (panels.length === 0 && !isLoading) {
            setIsLoading(true)
            service.getTasks(store.selectedListId)
                .then(({ tasks }) => {
                    store.setTasks(tasks);
                    setPanels(tasks);
                    setIsLoading(false)
                })
                .catch(error => {
                    setIsLoading(false)
                    console.error("Error fetching tasks:", error);
                    if (error.response.status === 401) {
                        navigate("/")
                    }
                })
                .finally(() => {
                    setIsLoading(false)
                    store.setIsRequestInProgress(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [panels, store, service]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(fetchTasks, [panels, store]);
    useEffect(() => {
        if (!store.selectedListId)
            navigate("/list")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.selectedListId])
    return (
        isLoading
            ? <Box display='flex' justifyContent='center'><CircularProgress /></Box>
            : <Collapse ghost>
                {panels.map(task => (
                    <Collapse.Panel key={task.id} header={<TaskPanelHeader task={task} />}>
                        <p>{task.description}</p>
                    </Collapse.Panel>
                ))
                }
            </Collapse >
    );
}

export default observer(ClickUpTasks);