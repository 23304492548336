import React from 'react'
import { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";

const RoundTable = ({ data, handleUpdate }) => {
  const [fields, setFields] = useState([])
  const toFirstLetterUpper = (str) => {
    return str.slice(0, 1).toUpperCase() + str.slice(1)
  }

  useEffect(() => {
    let newFields = []
    if(data.taskChanges) {
      for (let item of data.taskChanges) {
        if(item.newValue !== 'null')
          newFields.push(<Box key={`field-${newFields.length}`} display='flex' flexDirection='column' gap={0.5}>
            <Typography variant='body1' sx={{
              color: 'rgba(24, 28, 48, 0.48)'
            }}>{toFirstLetterUpper(item.field)}</Typography>
            <Typography variant='body1' sx={{
              color: 'rgb(24, 28, 48)'
            }}>{item.newValue}</Typography>
          </Box>)
      }
    }
    setFields(newFields)
  }, [data])

  return (
    <Box p={2} sx={{
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 2.5,
      bgcolor: 'rgba(185, 121, 249, 0.08)',
      gap: 2,
      height: 488
    }}>
      <Typography variant='body1' sx={{
        fontWeight: 600
      }}>{data.taskTitle}</Typography>
      <Box height={380} display='flex' flexDirection='column' gap={1.5}>
        {
          fields
        }
      </Box>
      <Button color="primary" sx={{
        bgcolor: 'primary.main',
        px: 2,
        py: 0.75,
        ':hover': {
          bgcolor: 'primary.light', // theme.palette.primary.main
          color: 'white',
        },
        textTransform: 'none',
        borderRadius: 2.5,
        width: 118,
      }} onClick={()=> {handleUpdate(data)}}>
        <Typography variant='body1' sx={{
          color: 'white',
          fontWeight: 600,
          lineHeight: '16px'
        }}>Apply changes</Typography>
      </Button>
    </Box>
  )
}

export default RoundTable;