import "../../css/Main.css";
import "../../css/IntegrationsScreen.css";
import clickUpLogo from "../../images/clickup.png";
import airtableLogo from "../../images/airtable.png";
import jiraLogo from "../../images/jira.png";
import asanaLogo from "../../images/asana.png";
import trelloLogo from "../../images/trello.png";
import {useContext, useEffect, useState} from "react";
import IntegrationContext from "../store/IntegrationStore";
import { Integration } from "../enums/Integration";
import CustomButton from "./CustomButton";
import { useNavigate } from "react-router-dom";
import ZoomContext from "../store/ZoomStore";
import {OnRunningContextChangeEvent} from "@zoom/appssdk";

declare let zoomSdk: any;

export default function IntegrationChooser() {
	const navigate = useNavigate()
	const integrationStore = useContext(IntegrationContext);

	const [counter, setCounter] = useState(0);
	const zoomStore = useContext(ZoomContext);

	async function configureSdk() {
		// to account for the 2 hour timeout for config
		const configTimer = setTimeout(() => {
			setCounter(counter + 1);
		}, 120 * 60 * 1000);

		try {
			// Configure the JS SDK, required to call JS APIs in the Zoom App
			// These items must be selected in the Features -> Zoom App SDK -> Add APIs tool in Marketplace
			const configResponse = await zoomSdk.config({
				capabilities: [
					"getMeetingUUID"
				],
				version: "0.16.0",
			});
			console.log(configResponse);

			zoomSdk.onRunningContextChange(async function (data: OnRunningContextChangeEvent) {

				if (data.runningContext === "inMeeting") {
					let meetingUUIDResponse = await zoomSdk.getMeetingUUID();
					zoomStore.setMeetingUUID(meetingUUIDResponse.meetingUUID);

				}
			})

			if (configResponse.runningContext === "inMeeting") {
				let meetingUUIDResponse = await zoomSdk.getMeetingUUID();
				zoomStore.setMeetingUUID(meetingUUIDResponse.meetingUUID);
			}

		} catch (error: any) {
			if (error.response && error.response.status === 401) {
				navigate("/")
			}
			console.log(error);
		}
		return () => {
			clearTimeout(configTimer);
		};
	}

	useEffect(() => {
		configureSdk();
	}, []);

	const onClickUpClick = () => {
		integrationStore.setIntegration(Integration.ClickUp);
		navigate("/list")
	}

	const onAirtableClick = () => {
		integrationStore.setIntegration(Integration.AirtTable);
		navigate("/airtable-list" )
	}
	const onJiraClick = () => { }
	const onAsanaClick = () => { }
	const onTrelloClick = () => { }

	return (
		<div className="flex-column-container integration-container">
			<div className= "integration-box">
				<div >
					<div className="title">Select Task Tracking System</div>
				</div>
				<div style={{ marginTop: "36px" }}>
					<CustomButton clickHandle={onClickUpClick} title="ClickUp" imgUrl={clickUpLogo} status={true} />
					<CustomButton clickHandle={onAirtableClick} title="Airtable" imgUrl={airtableLogo} status={true} />
					<CustomButton clickHandle={onJiraClick} title="Jira" imgUrl={jiraLogo} status={false} />
					<CustomButton clickHandle={onAsanaClick} title="Asana" imgUrl={asanaLogo} status={false} />
					<CustomButton clickHandle={onTrelloClick} title="Trello" imgUrl={trelloLogo} status={false} />
				</div>
			</div>
		</div>

	)
}