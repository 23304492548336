import {makeAutoObservable} from "mobx";
import {createContext} from "react";

class ZoomStore {
    meetingUUID = "";

    constructor() {
        makeAutoObservable(this);
    }

    setMeetingUUID(value) {
        this.meetingUUID = value;
    }
}

const ZoomContext = createContext(new ZoomStore());
ZoomContext.displayName = "ZoomContext";

export default ZoomContext;